import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/class",
    component: Layout,
    children: [
      {
        path: "Class",
        name: "myclass",
        component: () => import("@/views/class/class"),
      },
      {
        path: "classPlayer",
        name: "classPlayer",
        component: () => import("@/views/class/classPlayer"),
      },
    ],
  },
  {
    path: "/404",
    name: "patherrpage",
    component: () => import("@/views/404"),
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/login"),
  },
  // 404 page must be placed at the end !!!
  {
    path: "*",
    name: "errpage",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
