import {
  getToken,
  setToken,
  //   removeToken,
  setCookie,
  //   removeCookie,
} from "@/utils/auth";

// import { login } from "@/api/user";
// import { resetRouter } from "@/router";

const getDefaultState = () => {
  return {
    token: getToken(),
    openId: "",
    nickName: "",
    logo: "",
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NICKNAME: (state, nickName) => {
    state.nickName = nickName;
  },
  SET_OPENID: (state, openId) => {
    state.openId = openId;
  },
  SET_LOGO: (state, logo) => {
    state.logo = logo;
  },
  SET_STATE: (state, obj) => {
    state.token = obj.token;
    state.nickName = obj.nickName;
    state.openId = obj.openId;
    state.logo = obj.logo;
    setCookie("logo", obj.logo);
    setCookie("openId", obj.openId);
    setCookie("nickName", obj.nickName);
    setToken(obj.token);
  },
};

// const actions = {
//   // user login
//   login({ commit }, payload) {
//     commit;
//     const { data: userInfo, type } = payload;
//     const { phoneNum, pwd, verifyCode, signature, smsCode, bizId } = userInfo;
//     return new Promise((resolve, reject) => {
//       let obj = {};
//       switch (type) {
//         case "pwd":
//           obj = { userAccount: phoneNum, pwd: pwd, verifyCode, signature };
//           break;
//         case "sms":
//           obj = { phoneNum, smsCode, bizId };
//           break;
//       }
//       login(obj, type)
//         .then((response) => {
//           if (response.code == 0) {
//             // commit('SET_TOKEN', response.userId)
//             setCookie("phoneNum", phoneNum);
//             setCookie("role", response.role);
//             setCookie("isGuardComp", response.isGuardComp);
//             // commit('SET_NAME', phoneNum)
//             // setCookie('accountId', response.accountId)
//             setToken(response.token);
//             resetRouter();
//             resolve();
//           } else {
//             reject(response);
//           }
//         })
//         .catch((error) => {
//           console.log("error");
//           reject(error);
//         });
//     });
//   },

//   // get user info
//   //   getInfo({ commit, state }) {
//   //     return new Promise((resolve, reject) => {
//   //       getInfo(state.token)
//   //         .then((response) => {
//   //           const { data } = response;

//   //           if (!data) {
//   //             return reject("Verification failed, please Login again.");
//   //           }

//   //           const { name, avatar } = data;

//   //           commit("SET_NAME", name);
//   //           commit("SET_AVATAR", avatar);
//   //           resolve(data);
//   //         })
//   //         .catch((error) => {
//   //           reject(error);
//   //         });
//   //     });
//   //   },

//   //   mchDetai({ commit }) {
//   //     commit;
//   //     return new Promise((resolve, reject) => {
//   //       mchDetai({})
//   //         .then((response) => {
//   //           const { logoImg } = response;

//   //           if (!logoImg) {
//   //             return reject("Verification failed, please Login again.");
//   //           }

//   //           setCookie("logoImg", logoImg);
//   //           resolve();
//   //         })
//   //         .catch((error) => {
//   //           reject(error);
//   //         });
//   //     });
//   //   },

//   // user logout
//   //   logout({ commit, state }) {
//   //     return new Promise((resolve, reject) => {
//   //       logout(state.token)
//   //         .then(() => {
//   //           commit("SET_TOKEN", "");
//   //           commit("SET_ROLES", "");
//   //           resetRouter();
//   //           resolve();
//   //         })
//   //         .catch((error) => {
//   //           reject(error);
//   //         });
//   //     });
//   //   },

//   // qiniu token
//   //   qiniuToken({ commit }) {
//   //     return new Promise((resolve, reject) => {
//   //       getQiniuToken()
//   //         .then((response) => {
//   //           commit("SET_QINIUTOKEN", response.token);
//   //           setCookie("qiniuToken", response.token);
//   //           resolve(response);
//   //         })
//   //         .catch((error) => {
//   //           console.log(error.response);
//   //           reject(error);
//   //         });
//   //     });
//   //   },

//   // remove token
//   resetToken({ commit }) {
//     return new Promise((resolve) => {
//       commit("SET_TOKEN", "");
//       // commit('SET_NAME', '')
//       removeCookie("phoneNum");
//       removeCookie("role");
//       // removeCookie('accountId')
//       // removeCookie('qiniuToken')
//       removeToken();
//       resolve();
//     });
//   },
// };

export default {
  namespaced: true,
  state,
  mutations,
};
