import { addListener, launch } from "devtools-detector";
import { Notification } from "element-ui";
window.onload = function () {
  addListener((isOpen) => {
    if (isOpen) {
      const elements = document.querySelector("#app");
      elements.remove();
      setTimeout(() => {
        Notification({
          title: "提示",
          message: "请关闭控制台！",
          duration: 0,
        });
      }, 1000);
    } else {
      window.location.reload();
    }
  });
  // 2. launch detect
  launch();
};
