<template>
  <div>
    <headBox />
    <div class="center">
      <Appmain />
    </div>
  </div>
</template>

<script>
import headBox from "@/components/business/yedengHead.vue";
import Appmain from "./components/Appmain.vue";

export default {
  name: "layOut",
  components: { headBox, Appmain },
};
</script>

<style scoped>
.center {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
