<template>
  <div class="headbox">
    <div class="yedenghead">
      <img
        class="logo"
        :src="require(`@/assets-${SITEMSG.id}/website/logo/logotitle.png`)"
        alt="img"
      />
      <nav class="nav">
        <div v-for="(item, index) in navList" :key="index">
          <router-link class="navitem" :to="item.path">
            <span>{{ item.name }}</span>
          </router-link>
        </div>
      </nav>
      <div class="user">
        <userDrop />
      </div>
    </div>
  </div>
</template>

<script>
import userDrop from "./layout/userDrop.vue";
import { navList } from "@/const";
export default {
  name: "yedengHead",
  components: { userDrop },
  data() {
    return {
      navList,
    };
  },
};
</script>

<style lang="less" scoped>
.headbox {
  width: 100%;
  height: 63px;
  padding: 10px;
  background: #fff;
  .yedenghead {
    width: 1220px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 120px;
      object-fit: contain;
    }
  }
  .nav {
    width: calc(100% - 380px);
  }
}
.navitem {
  font-size: 14px;
}

@media screen and (min-width: 960px) and (max-width: 1220px) {
  .headbox {
    .yedenghead {
      width: 1000px;
    }
  }
}
</style>
