import Vue from "vue";

import "normalize.css";
import "./style/index.less";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "./theme/index.css";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueAliplayerV2 from "vue-aliplayer-v2";
// import { Notification } from "element-ui";
// import { addListener, launch } from "devtools-detector";

import "./promission";
import "./detector";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueAliplayerV2);

import { getSiteMsg } from "@/api/site";

const getSite = function () {
  try {
    return getSiteMsg();
  } catch (error) {
    console.log(error);
  }
};
getSite().then((res) => {
  let siteMsg = res.data;
  const { siteName } = siteMsg;
  // if (siteName.indexOf("yedeng") > -1) {
  //   siteMsg.name = "夜灯";
  //   siteMsg.id = "yedeng";
  // } else {
  //   siteMsg.name = "鲸落";
  //   siteMsg.id = "jingluo";
  // }
  if (siteName.indexOf("yedeng") > -1) {
    siteMsg.name = "夜灯";
    siteMsg.id = "yedeng";
    siteMsg.ip = "yedengtech.com";
    siteMsg.origin = "https://oss.yedengtech.com"; // 七牛
    siteMsg.copyAddress = "京ICP备2023021472号-1";
    siteMsg.address = "北京";
  } else {
    siteMsg.name = "鲸落";
    siteMsg.id = "jingluo";
    siteMsg.ip = "jingluotech.com";
    siteMsg.origin = "https://oss.001ke.com"; // 七牛
    siteMsg.copyAddress = "闽ICP备2023018996号-1";
    siteMsg.address = "福建";
  }
  // set page title
  Vue.prototype.SITEMSG = siteMsg;
  document.title = siteMsg.name + "课堂-网页学习";
  setTimeout(() => {
    new Vue({
      el: "#app",
      router,
      store,
      render: (h) => h(App),
    });
  }, 20);
});
