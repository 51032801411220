const getters = {
  nickName: (state) => state.user.nickName,
  logo: (state) => state.user.logo,
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  avatar: (state) => state.user.avatar,
  permission_routes: (state) => state.permission.routes,
  addRoutes: (state) => state.permission.addRoutes,
  errorLogs: (state) => state.errorLog.logs,
  visitedViews: (state) => state.tagsView.visitedViews,
  qiniuToken: (state) => state.user.qiniuToken,
};
export default getters;
