<template>
  <section class="app-main" @mousewheel="getDetectZoom">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>
<script>
// import { isPc, setDomZoom, detectZoom } from "@/utils";
// import { isPc, setDomZoom } from "@/utils";
export default {
  name: "appMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
  mounted() {
    // setDomZoom();
    // this.getDetectZoom();
    // if (!isPc()) {
    //   // setDomZoom();
    //   // this.getDetectZoom();
    // }
  },
  methods: {
    // TODO 有待完善
    getDetectZoom() {
      // setTimeout(() => {
      //   const screenratio = detectZoom();
      //   if (screenratio >= 150) {
      //     const de = (150 / screenratio).toFixed(2);
      //     // console.log(de.toFixed(2));
      //     document.body.style.zoom = de;
      //   } else {
      //     // 默认放大1.1
      //     document.body.style.zoom = 1.1;
      //   }
      // }, 100);
    },
  },
};
</script>
