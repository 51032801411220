<template>
  <div class="flex flexcenter" style="position: relative">
    <el-avatar v-if="logo" class="mg-r05" :src="logo" size="small"></el-avatar>
    <el-avatar
      v-else
      class="mg-r05"
      icon="el-icon-user-solid"
      size="small"
    ></el-avatar>
    <div style="position: relative">
      <el-dropdown trigger="click" ref="eldrop">
        <span class="el-dropdown-link">
          <span class="username">{{ nickName }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click="loginout">
            <span @click="loginout">登出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getCookie } from "@/utils/auth";
export default {
  name: "userDrop",
  data() {
    return {
      nickName: "",
      logo: "",
    };
  },
  computed: {},
  mounted() {
    this.nickName =
      getCookie("nickName") == "undefined" ? `学员` : getCookie("nickName");

    this.logo =
      getCookie("logo") || require(`@/assets-${this.SITEMSG.id}/avatar.png`);
  },
  methods: {
    // 登出
    loginout() {
      console.log(1);
      this.$store.commit("user/RESET_STATE");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/style/variables.less");

.username {
  cursor: pointer;
}
.username:hover {
  color: @theme-color;
}
</style>
