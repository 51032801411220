import axios from "axios";
// import { getToken } from "@/utils/auth";
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  // timeout: 5000 // 请求超时时间
});

// const exportList = ["/ye/rest/exportRecord/download"];

// service.defaults.headers.post["Content-Type"] = "multipart/form-data";

// request拦截器
// service.interceptors.request.use(
//   (config) => {
//     console.log("启用res拦截器");
//     let Token = getToken();
//     Token = "token-06f62e1c-8f21-4697-b790-ca46694a4b89-1696729826769";
//     Token = "11";
//     console.log(Token);
//     if (Token) {
//       config.headers["X-Token"] = Token; // 让每个请求携带自定义token 请根据实际情况自行修改
//     }
//     const url = config.url;
//     console.log(config);
//     if (exportList.includes(url)) {
//       config.responseType = "blob";
//     }
//     return config;
//   },
//   (error) => {
//     console.log("res拦截器报错");
//     // Do something with request error
//     console.log(error); // for debug
//     Promise.reject(error);
//   }
// );

// response 拦截器
// service.interceptors.response.use(
//   (response) => {
//     const url = response.config.url;
//     if (exportList.includes(url)) {
//       let fileName = null;
//       if (response.headers["content-disposition"]) {
//         fileName = response.headers["content-disposition"].replace(
//           /attachment; filename=/,
//           ""
//         );
//       }
//       response.data = {
//         file: response.data,
//         fileName: fileName,
//       };
//     }
//     return response.data;
//   },
//   (error) => {
//     if (error.response.status == 403) {
//       Message.error({ message: "登录超时,请重新登录!" });
//       store.dispatch("user/resetToken").then(() => {
//         location.reload();
//       });
//     } else {
//       console.log(error.message); // for debug
//       Message({
//         message: error.message,
//         type: "error",
//         duration: 5 * 1000,
//       });
//     }
//     return Promise.reject(error);
//   }
// );

export default service;
